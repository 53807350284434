.home-container {
    margin-top: 20px;
}

.home-img {
    width: 100%;
    height: auto;
    border-radius: 25px;
}

#btn {
    color: black;
    background-color: rgb(253, 147, 77);
    margin-bottom: 10px;
}

.modal-header {
    background-color: rgb(253, 192, 151);
}