.testimonial-slider {
    font-family: 'Oooh Baby', cursive;
    background-color: #ffe57f;
    border-radius: 25px;
    margin-top: 10px;
  }
  
  .testimonial-slider__title {
    font-size: 2rem;
    color: #3a3a3a;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .testimonial-slider__item {
    padding: 1rem;
  }
  
  .testimonial-slider__content {
    background-color: #f2f2f2;
    border-radius: 5px;
    padding: 1rem;
    text-align: center;
  }
  
  .testimonial-slider__comment {
    font-size: 1rem;
    color: #5a5a5a;
  }
  
  .testimonial-slider__name {
    font-size: 1rem;
    color: #3a3a3a;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .testimonial-slider__rating span {
    display: inline-block;
    font-size: 1rem;
    color: #f1c40f;
  }