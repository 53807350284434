.package-modal-header {
    background-color: rgb(253, 192, 151);
    color: black;
  }
  
  .package-modal-body {
    background-color: white;
    color: black;
  }
  
  .package-modal-button {
    background-color: rgb(253, 192, 151);
    border-color: rgb(253, 192, 151);
    color: black;
  }
  
  .package-modal-button:hover {
    background-color: rgb(230, 170, 130); /* a darker shade of your header color for hover effect */
    border-color: rgb(230, 170, 130);
  }
  