/* About.css */

h1, h2 {
    color: #354458; /* Change the color according to your preference */
}

p {
    font-size: 1.1em;
    line-height: 1.6;
    color: #666;
}

.card {
    margin-bottom: 2em;
}

.card-title {
    color: #354458; /* Change the color according to your preference */
}

.card-text {
    color: #666;
}
.teamwork {
    height: 100%;
    width: auto; 
}