

.brand-and-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.nav-link {
    color: rgb(25, 0, 247) !important;
    font-size: 1.25rem !important;
}

.nav-link:hover {
    color: rgb(5, 0, 37) !important;
    transform: translateY(-5px);
    
}

.nav-link.active {
    color: rgb(13, 1, 128) !important;
}

.navbar-toggler {
    border-color: black !important;
}

.logo {
    max-height: 150px;
    margin: 0 auto;
    border-radius: 10px;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='black' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
}

.motto {
    /* Add styles for the motto text */
    color: gold;
    font-size: 1.25rem;
}

.navbar {
    display: flex;
    justify-content: space-between;
    background-color: rgb(253, 192, 151);
}

.business-name {
    font-size: 2rem;
    font-weight: 600;
    margin-right: auto;
    margin-left: auto;
}

/* @media (max-width: 768px) {
  .business-name {
    display: none;
  }
  .logo {
    max-height: 50px;
    max-width: 50px;
  }
} */