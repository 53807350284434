/* PackagePage.css */

h1 {
    color: #354458; /* Change the color according to your preference */
}

.card {
    margin-bottom: 2em;
    text-align: center;
}

.card-header {
    color: #fff;
    background-color: #354458; /* Change the color according to your preference */
}

.card-title {
    color: #354458; /* Change the color according to your preference */
}

.card-text {
    color: #666;
}
